<template>
  <div>
    <div class="air__utils__heading">
      <h5>
        <span class="mr-3">Daftar Forum</span>
        <div class="pull-right">
          <router-link to="/forum/create">
            <button class="btn btn-with-addon mr-auto btn-primary">
                  <span class="btn-addon"
                  ><i class="btn-addon-icon fe fe-plus-circle"></i
                  ></span>
              Tambah
            </button>
          </router-link>
        </div>
      </h5>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex flex-column justify-content-center mr-auto">
              <h5 class="mb-0">Semua Forum</h5>
            </div>
            <div class="d-flex flex-column justify-content-center">
              <a-input-search 
                v-model="search"
                placeholder="Temukan forum" 
                enter-button 
                @search="onSearch"/>
            </div>
          </div>
          <div class="card-body">
            <div class="air__utils__scrollTable">
              <a-table
                  :columns="columns"
                  :row-key="record => record.id"
                  :data-source="data"
                  :pagination="pagination"
                  :loading="loading"
                  @change="handleTableChange"
              >

                <span slot="image" slot-scope="image">
                  <a :href="image" target="_blank" v-if="image">
                  <img :src="image" class="width-64"/>
                  </a>
                  <img src="resources/img-default.png" class="width-64" v-else/>
                </span>
                <span slot="action" slot-scope="text, record">
                  <router-link :to="{ path: '/forum/detail/' + text }">
                    <button class="btn btn-sm btn-light mr-2">
                      <i class="fe fe-eye mr-2"/>
                      Lihat
                    </button>
                  </router-link>
                  <router-link :to="{ path: '/forum/update/' + text }">
                    <button class="btn btn-sm btn-light mr-2">
                      <i class="fe fe-edit mr-2"/>
                      Ubah
                    </button>
                  </router-link>
                  <a-tooltip placement="topLeft" title="Tutup topik" v-if="(record.is_commentabled === 1)">
                    <button
                        class="btn btn-sm btn-warning mr-2"
                        @click="deactivate(text)"
                    >
                      <small>
                        <i class="fe fe-lock" />
                      </small>
                    </button>
                  </a-tooltip>
                   <a-tooltip placement="topLeft" title="Buka topik" v-else>
                    <button
                        class="btn btn-sm btn-info mr-2"
                        @click="activate(text)"
                    >
                      <small>
                        <i class="fe fe-unlock" />
                      </small>
                    </button>
                  </a-tooltip>
                  <a-tooltip placement="topLeft" title="Hapus">
                    <button
                        class="btn btn-sm btn-danger"
                        @click="modalDeleteContent(text)"
                    >
                      <small>
                        <i class="fe fe-trash"/>
                      </small>
                    </button>
                  </a-tooltip>
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex"
import {deleteForum, getListForum, toggleForum} from "@/api/forum.api";
import ForumModel from "./ForumModel"

const columns = [
  {
    title: "Gambar",
    dataIndex: "image.url",
    scopedSlots: {customRender: 'image'}
  },
  {
    title: "Forum",
    dataIndex: "title",
  },
  {
    title: "Kategori",
    dataIndex: "category.name",
  },
  {
    title: "Pembuat",
    dataIndex: "creator.fullname",
  },
  {
    title: "Pengubah",
    dataIndex: "updater.fullname",
  },
  {
    title: "Tanggal Terbit",
    dataIndex: "created_at",
  },
  {
    title: "Tanggal Ubah",
    dataIndex: "updated_at",
  },
  {
    title: "Jumlah Komentar",
    dataIndex: "total_comment",
  },
  {
    title: "Aksi",
    dataIndex: "id",
    scopedSlots: {customRender: "action"}
  }
];

export default {
  data() {
    return {
      data: [],
      pagination: ForumModel.table.pagination,
      loading: false,
      columns,
      search: '',
      // Route
      curRoute: ''
    };
  },
  mounted() {
    this.search = this.$store.state.forum.filter.searchText
    this.curRoute = this.$route.fullPath;
    this.fetchData();
  },
  beforeDestroy() {
    const nextRoute = this.$route.fullPath;
    if(
      !nextRoute.includes('forum/detail') && 
      !nextRoute.includes('forum/update')) {
      this.$store.commit('CLEAR_FORUM_FILTER')
      this.resetPagination();
    }
  },
  methods: {
    ...mapMutations([
      'UPDATE_DATA_FORUM',
      'UPDATE_FORUM_SEARCH'
    ]),
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.fetchData();
    },
    async fetchData() {
      try {
        this.loading = true;
        const params = {
          page: this.pagination.current,
          limit: this.pagination.pageSize,
          search: this.search
        }
        let response = await getListForum(params);
        if (response.status == 200) {
          this.data = response.data.results;
          this.pagination.total = response.data.paging.total;
          this.loading = false;
          this.UPDATE_DATA_FORUM(this.data);
        }
      } catch (error) {/**/
      }
    },
    async onSearch(value) {
      this.UPDATE_FORUM_SEARCH(value)
      this.resetPagination();
      await this.fetchData()
    },
    modalDeleteContent(_id_content) {
      this.$modal.confirm({
        title: "Hapus Forum",
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        content: "Apakah Anda yakin untuk menghapus Forum ini?",
        onOk: () => {
          this.deleteContents(_id_content);
        },
        onCancel() {
        }
      });
    },
    async deleteContents(id_content) {
      try {
        this.$nprogress.start();
        await deleteForum(id_content);
        this.$notification["success"]({
          message: "Sukses",
          description: "Topik forum berhasil dihapus"
        });
        await this.fetchData();
      } catch (error) {
        // console.log(error);
      }
      this.$nprogress.done();
    },
    async deactivate(record_id){
      try {
        this.$nprogress.start();
        await toggleForum(record_id);
        this.$notification["success"]({
          message: "Sukses",
          description: "Forum berhasil ditutup"
        });
        await this.fetchData();
      } catch (error) {/**/}
      this.$nprogress.done();
    },
    async activate(record_id){
      try {
        this.$nprogress.start();
        await toggleForum(record_id);
        this.$notification["success"]({
          message: "Sukses",
          description: "Forum dibuka kembali"
        });
        await this.fetchData();
      } catch (error) {/**/}
      this.$nprogress.done();
    },
    resetPagination() {
      this.pagination.current = 1
    }
  }
};
</script>
<style scoped>
.width-64 {
  max-width: 85px;
  max-height: fit-content;
  width: auto;
  height: auto;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
