export default {
  table: {
		/**
     * Default pagination property
     */
		pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    }
	},

  data: {
    user: {
      id: null,
      fullname: null,
      avatar: {
        url: null
      },
      selected: false
    }
  },
  methods: {
    bindData(users, filterUsers, data) {
      this.bindUser(users, data)
      this.bindUser(filterUsers, data)
    },
    
    bindUser(result, data) {
      for(const user of data) {
        result.push({
          id: user.id,
          fullname: user.fullname,
          avatar: {
            url: user.avatar.url
          },
          selected: user.selected || false
        })
      }
    },

    setSelected(users, filterUsers, id) {
      const filterArgs = (it) => it.id == id
      const userIdx = users.findIndex(filterArgs)
      const filterUserIdx = filterUsers.findIndex(filterArgs)
      users[userIdx].selected = !users[userIdx].selected
      filterUsers[filterUserIdx].selected = !filterUsers[filterUserIdx].selected
    },

    getSelected(users) {
      return users.filter(it => it.selected)
    }
  }
}
